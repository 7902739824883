/* accommodate Bootstrap 5 with React-Bootstrap-Table2, which is intended for Bootstrap 4 */
.react-bootstrap-table .caret {
  display: inline-block;
  margin: 0 !important;
  color: #97191C !important;
  vertical-align: 0.255em;
  content: "";
  border-right: 0.25em solid transparent;
  border-left: 0.25em solid transparent;
  border-top: 0.25em solid;
  border-bottom: 0;
  opacity: 0.35;
}

.react-bootstrap-table .dropup .caret {
  border-top: 0;
  border-bottom: 0.3em solid;
}

.react-bootstrap-table .react-bootstrap-table-sort-order .caret {
  margin-right: 10px;
  opacity: 1;
}

.react-bootstrap-table-sort-order, .react-bootstrap-table .order {
  margin-left: 5px;
}
/* end React-Bootstrap-Table2 */

h1 span img {
  max-width: 110px;
  max-height: 110px;
}

th img {
  max-width: 22px;
  margin: 0 10px;
}

.table {
  margin: 0 !important;
}

.react-bootstrap-table {
  border-radius: 7px;
  overflow: auto;
  border: 1px solid rgb(222, 226, 230);
  box-shadow: 0 .125rem .25rem rgba(0,0,0,.075);
  background-color: white;
  max-height: 600px;
}

.react-bootstrap-table .table > :not(:first-child) {
  border-top: unset;
}

.react-bootstrap-table .table {
  table-layout: auto;
}

.react-bootstrap-table .order {
  display: inline-flex;
  align-items: center;
}

body {
  background-image: url(../public/background.png);
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 100vh;
  padding-bottom: calc(max(10vw, 50px));
}